import React, { useEffect, useContext, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import ApplicationTable from '../../components/ApplicationTable';
import ReviewerNav from '../../components/ReviewerNav';
import { HeedContext } from '../../gatsby-theme-carbon/components/Layout';
import ReviewerLayout from '../../gatsby-theme-carbon/components/ReviewerLayout';
import { fetchAuthenticatedContent, UserContext } from '@parallelpublicworks/entitysync'

function Reviewer({ data, pageContext, location }) {

  const userContext = useContext(UserContext)
  const userState = userContext && typeof userContext[0] !== 'undefined' ? userContext[0] : null
  // const userData = userState && userState.userData ? userState.userData : {}
  const { user } = useContext(HeedContext);

  const { allNodeTask } = data;

  return (
    <ReviewerLayout>
      <div>
        <div id="application-title" className="padd">
          <h2>Applications submitted</h2>
        </div>
        <ApplicationTable
          allNodeTask={allNodeTask}
          location={location}
        />
      </div>
    </ReviewerLayout>
  )

}

export default function ReviewerPage({ pageContext, location, data }) {
  pageContext = { ...pageContext, frontmatter: { title: "Reviews", slug: '/reviewer' } };
  return (
    <Reviewer pageContext={pageContext} location={location} data={data} />
  )
}

export const query = graphql`
{
  allNodeTask(sort: {fields: title, order: ASC}) {
    edges {
        node {
            id
            drupal_id
            title
            field_description {
              value
            }
            internal {
              type
            }
            relationships {
              field_questions {
                ... on node__text_question {
                  drupal_id
                  title
                  field_is_required
                  field_identifier
                  field_type
                  field_number_type
                  internal {
                    type
                  }
                }
                ... on node__checkbox_question {
                  drupal_id
                  title
                  field_is_multiple
                  field_is_required
                  field_identifier
                  internal {
                    type
                  }
                  relationships {
                    field_options {
                      drupal_id
                      title
                    }
                    field_invalid_options {
                      drupal_id
                    }
                  }
                }
                ... on node__address_question {
                  drupal_id
                  field_identifier
                  field_is_required
                  field_exclude_fields
                  title
                  internal {
                    type
                  }
                }
                ... on node__file_question {
                  drupal_id
                  title
                  field_is_required
                  field_identifier
                  internal {
                    type
                  }
                }
                ... on node__lor_question {
                  drupal_id
                  title
                  field_number_of_letters 
                  internal {
                    type
                  }
                }
              }
            } 
        }
    }
  }
}
`
